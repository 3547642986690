import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SubpageHeader from "../../components/subpageHeader"
import { StaticQuery, graphql } from "gatsby"

const AboutUsPage = ({ pageContent }) => {
  const lang = "pl"
  return (
    <Layout
      lang={lang}
      translationEN="/en/about-us/"
      translationFO="/um-okkum/"
    >
      <SEO
        title={
          pageContent.seo.title ? pageContent.seo.title : pageContent.title
        }
        description={pageContent.seo.metaDesc && pageContent.seo.metaDesc}
        image={
          pageContent.seo.opengraphImage &&
          pageContent.seo.opengraphImage.localFile.publicURL
        }
      />
      <SubpageHeader title="O nas" />
      <section className="about-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div
                className="about-main__desc"
                dangerouslySetInnerHTML={{
                  __html: pageContent.aboutUs.firstText,
                }}
              />
              <div className="about-main__icons">
                {lang === "en" ? (
                  <img
                    src={require("../../assets/images/about-icons-eng.jpeg")}
                    alt=""
                    className="img-fluid"
                  />
                ) : lang === "pl" ? (
                  <img
                    src={require("../../assets/images/about-icons-pl.jpeg")}
                    alt=""
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={require("../../assets/images/about-icons-fo.jpeg")}
                    alt=""
                    className="img-fluid"
                  />
                )}
              </div>
              <div className="about-main__icons about-main__icons--mobile">
                {lang === "en" ? (
                  <img
                    src={require("../../assets/images/about-icons-mobile-eng.jpeg")}
                    alt=""
                    className="img-fluid"
                  />
                ) : lang === "pl" ? (
                  <img
                    src={require("../../assets/images/about-icons-mobile-pl.jpeg")}
                    alt=""
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={require("../../assets/images/about-icons-mobile-fo.jpeg")}
                    alt=""
                    className="img-fluid"
                  />
                )}
              </div>
              <div className="row">
                {pageContent.aboutUs.persons.map(item => (
                  <div className="col-md-4">
                    <div className="single-person">
                      <img
                        src={item.image.sourceUrl}
                        alt={item.name}
                        className="img-fluid single-person__image"
                      />
                      <p className="single-person__name">{item.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        pageContent: wpPage(id: { eq: "cG9zdDo1OTY=" }) {
          seo {
            title
            metaDesc
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
          aboutUs {
            fieldGroupName
            firstText
            persons {
              fieldGroupName
              image {
                sourceUrl
              }
              letter
              name
            }
          }
        }
      }
    `}
    render={data => <AboutUsPage {...data} />}
  />
)
